import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import FullScreenDialog from '@targetable/targetable-web-framework/lib/react/containers/FullScreenDialog/FullScreenDialog';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';
import { getFacebookAddress } from '@targetable/targetable-web-framework/lib/services/store';

import {
  selectShowFacebookPageSelectModal,
  selectStores,
  selectUser,
} from '../../selectors';
import { facebookPageSelect } from '../../actions';
import { mapConnectedFacebookPages } from '../../services/facebook';
import FacebookPageAdAccountManageError from '../FacebookPageAdAccountManageError/FacebookPageAdAccountManageError';
import api from '../../services/api';
import logger from '../../services/logger';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  top: {
    flex: 1,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    width: 200,
  },
  errorContainer: {
    marginTop: theme.spacing(2),
  },
  details: {
    color: '#8c8f9f',
  },
  chip: {
    fontSize: '0.75rem',
    fontWeight: 500,
    backgroundColor: 'white',
    lineheight: 1.33,
    color: '#007bff',
    border: '1px #007bff solid',
  },
  text: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
  },
  errorDetails: {
    fontSize: '11px',
    color: '#ee3426',
  },
  warningDetails: {
    fontSize: '11px',
    color: '#d94100',
  },
  blueLabel: {
    fontSize: '11px',
    color: '#007bff',
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

/**
 * Returns an array of business associated with a Facebook User
 * @param {string} fbUserId The Id of the Facebook User selected
 * @param {string} tgtUserId The Id of the Targetable User
 * @returns The User's associated businesses
 */
const getUserBusinesses = async (fbUserId, tgtUserId) => {
  if (fbUserId) {
    try {
      const result = await api.getFacebookBusinesses(fbUserId, tgtUserId);
      return result;
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'getUserBusinesses' },
        params: { fbUserId, tgtUserId },
      });
    }
  }
  return [];
};

/**
 * Returns an array of pages associated with a Facebook Business
 * @param {string} businessId The Id of the Facebook Business selected
 * @param {string} userId The Id of the Facebook User selected
 * @param {string} accessToken The user's Facebook access token
 * @returns The Business's associated pages
 */
const getBusinessPagesWithTasks = async (businessId, userId, accessToken) => {
  if (businessId && userId) {
    try {
      const result = await api.getBusinessPagesWithTasks(businessId, userId, accessToken);
      return result;
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'getBusinessPagesWithTasks' },
        params: { businessId, userId },
      });
    }
  }
  return [];
};

const isLocationPage = (page) => page?.locations
  && page.locations.length
  && page.locations[0]?.latitude
  && page.locations[0]?.longitude
  && getFacebookAddress(page?.locations[0]);

const validPagePermissions = (page) => {
  const tasks = page?.tasks || [];
  return tasks.includes('MANAGE') && page?.isPublished;
};

const FacebookPageSelectModal = () => {
  const classes = useStyles();
  const { show, retry, store } = useSelector(selectShowFacebookPageSelectModal);
  const stores = useSelector(selectStores);
  const user = useSelector(selectUser);
  const [businessPages, setBusinessPages] = React.useState([]);
  const [loadingPages, setLoadingPages] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState({});
  const [userBusinesses, setUserBusinesses] = React.useState([]);
  const [loadingBusinesses, setLoadingBusinesses] = React.useState(false);
  const [selectedBussiness, setSelectedBusiness] = React.useState({});

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    dispatch(facebookPageSelect({
      ...{
        pageId: selectedPage.id,
        ...selectedPage,
        id: selectedBussiness.id,
        businessName: selectedBussiness.name,
      },
    }));
  }, [dispatch, selectedPage, selectedBussiness]);

  const handleRetry = useCallback(() => {
    setUserBusinesses([]);
    setLoadingBusinesses(false);
    setSelectedBusiness({});
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(facebookPageSelect(null));
  }, [dispatch]);

  useEffect(() => {
    if (!show) {
      setSelectedPage({});
      setBusinessPages([]);
      setSelectedBusiness({});
    }
  }, [show]);

  useEffect(() => {
    setSelectedPage({});
    const facebookIntegration = getUserFacebookIntegration(user);
    const accessToken = _.get(facebookIntegration, 'accessToken');
    const userId = _.get(facebookIntegration, 'userId');
    const loadBusinessPages = async () => {
      const pages = await getBusinessPagesWithTasks(selectedBussiness.id, userId, accessToken);
      if (_.isArray(pages)) {
        setBusinessPages(_.sortBy(pages, ['name']));
        if (pages.length === 1 && validPagePermissions(pages[0])) {
          setSelectedPage(pages[0]);
        }
      }
      setLoadingPages(false);
    };

    if (selectedBussiness?.id
      && userId && accessToken) {
      setLoadingPages(true);
      loadBusinessPages();
    }

    if (!selectedBussiness?.id) {
      setBusinessPages([]);
    }
  }, [selectedBussiness, user]);

  useEffect(() => {
    const facebookIntegration = getUserFacebookIntegration(user);
    const userId = _.get(facebookIntegration, 'userId');
    const loadBusinesses = async (fbUserId, tgtUserId) => {
      const businesses = await getUserBusinesses(fbUserId, tgtUserId);
      if (_.isArray(businesses)) {
        setUserBusinesses(_.sortBy(businesses, ['name']));
        if (businesses.length === 1) {
          setSelectedBusiness(businesses[0]);
        }
      }
      setLoadingBusinesses(false);
    };

    if (show && userId && _.isEmpty(userBusinesses) && !loadingBusinesses) {
      setLoadingBusinesses(true);
      loadBusinesses(userId, user.id);
    }
  }, [userBusinesses, user, loadingBusinesses, show]);

  if (!show) {
    return null;
  }

  const filteredFacebookPages = mapConnectedFacebookPages(businessPages, stores);
  const locations = selectedPage?.locations || [];
  const missingLatLong = !_.isEmpty(selectedPage) && locations.filter(
    (location) => location.latitude && location.longitude,
  ).length === 0;
  let ErrorContent;
  switch (true) {
    case _.get(selectedPage, 'warnings.adAccountsManage'):
      ErrorContent = FacebookPageAdAccountManageError;
      break;
    default:
      ErrorContent = null;
  }
  const showErrorMessage = selectedPage && ErrorContent;
  const formDisabled = loadingBusinesses;
  const getPageErrors = (page) => {
    const tasks = page?.tasks || [];
    const isPublished = page?.isPublished;
    return (
      <>
        {!isPublished && (
          <Typography
            className={classes.errorDetails}
            component="div"
            variant="caption"
          >
            Page is NOT published
          </Typography>
        )}
        {!tasks.includes('MANAGE') && (
          <Typography
            className={classes.errorDetails}
            component="div"
            variant="caption"
          >
            Missing MANAGE permission
          </Typography>
        )}
        {!isLocationPage(page) && (
          <Typography
            className={classes.warningDetails}
            component="div"
            variant="caption"
          >
            Missing Latitude/Longitude or Address Information
          </Typography>
        )}
        {page?.isConnected && (
          <Typography
            className={classes.blueLabel}
            component="div"
            variant="caption"
          >
            Already connected to Store:
            {' '}
            {page?.storeName}
          </Typography>
        )}
      </>
    );
  };

  const getBusinessErrors = (business) => {
    const roles = business?.permitted_roles || [];
    return (
      <>
        {!roles.includes('ADMIN') && (
          <Typography
            className={classes.warningDetails}
            component="div"
            variant="caption"
          >
            Missing ADMIN permission
          </Typography>
        )}
      </>
    );
  };

  return (
    <div data-cy="FacebookPageSelectModal_component">
      <FullScreenDialog
        open
        onClose={() => handleCancel()}
        title={t('select_facebook_page')}
      >
        <PageContentContainer className={classes.container}>
          <Box className={classes.top}>
            <Grid container spacing={3}>
              <Typography variant="h2" className={classes.title}>
                {t('facebook_page_select_modal_title', { storeName: _.get(store, 'name') })}
              </Typography>
              {userBusinesses.length === 0 && !loadingBusinesses && (
                <>
                  <Typography variant="subtitle1" className={classes.title}>
                    {t('facebook_page_select_modal_no_pages_title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('facebook_page_select_modal_no_businesses_body')}
                  </Typography>
                </>
              )}
              {userBusinesses.length > 0 ? (
                <Grid xs={12} item>
                  <Autocomplete
                    value={!loadingBusinesses ? selectedBussiness : null}
                    options={userBusinesses}
                    loading={loadingBusinesses}
                    loadingText={<ComponentLoading waitKey="loading" loading>{null}</ComponentLoading>}
                    onChange={(e, option) => setSelectedBusiness(option)}
                    getOptionLabel={(page) => page?.name || ''}
                    getOptionSelected={(option) => option?.id === selectedBussiness?.id}
                    renderInput={(params) => (
                      <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label={t('facebook_business')}
                        variant="outlined"
                        fullWidth
                        placeholder={t('facebook_business_select_placeholder')}
                        SelectProps={{
                          classes: { icon: classes.selectIcon },
                        }}
                      />
                    )}
                    renderOption={(option) => (
                      <Grid container spacing={1} justifyContent="flex-start" data-cy="facebookPageSelectModal_business_item">
                        <Grid item xs={12} md={12} lg={12}>
                          <div>{option.name}</div>
                          <Typography className={classes.details} component="div" variant="caption">{`Business Id: ${option.id}`}</Typography>
                          {getBusinessErrors(option)}
                        </Grid>
                      </Grid>
                    )}
                    filterOptions={(options, { inputValue }) => _.filter(
                      options,
                      (option) => option?.name?.toLowerCase()
                        .includes(inputValue.toLowerCase())
                        || option?.id?.toLowerCase().includes(inputValue.toLowerCase()),
                      )}
                    disabled={formDisabled}
                  />
                </Grid>
              ) : <ComponentLoading waitKey="loading" useLoaderCopy={false} loading={!userBusinesses.length}>{null}</ComponentLoading>}
              <Grid xs={12} item>
                {userBusinesses.length > 0 && (
                  <Autocomplete
                    value={selectedPage}
                    options={filteredFacebookPages}
                    loading={loadingPages}
                    loadingText={<ComponentLoading waitKey="loading" loading>{null}</ComponentLoading>}
                    onChange={(e, option) => setSelectedPage(option)}
                    getOptionLabel={(page) => page?.nameWithLocationDescriptor || ''}
                    getOptionSelected={(option) => option?.pageId === selectedPage?.id}
                    getOptionDisabled={(option) => !validPagePermissions(option)
                      || option?.isConnected}
                    renderInput={(params) => (
                      <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label={t('facebook_page')}
                        variant="outlined"
                        fullWidth
                        placeholder={t('facebook_page_select_placeholder')}
                        SelectProps={{
                          classes: { icon: classes.selectIcon },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <>
                                {!_.isEmpty(selectedPage) && (
                                  <Avatar alt="Page avatar" src={selectedPage?.avatar} className={classes.smallAvatar} />
                                )}
                                {params.InputProps && params.InputProps.endAdornment}
                              </>
                            ),
                            endAdornment: (
                              <>
                                {!_.isEmpty(selectedPage) && (
                                  <Chip
                                    size="small"
                                    className={classes.chip}
                                    label={!isLocationPage(selectedPage) ? t('brand_page_label') : t('location_page_label')}
                                    data-cy="facebookPageSelectModal_pageType_chip"
                                  />
                                )}
                                {params.InputProps && params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                      />
                    )}
                    renderOption={(option) => (
                      <Grid container spacing={1} justifyContent="flex-start" data-cy="facebookPageSelectModal_page_item">
                        <Grid item xs={9} md={9} lg={9}>
                          <Grid container>
                            <Grid item xs={2} md={1} lg={1}>
                              <Avatar alt="Page avatar" src={option?.avatar} className={classes.smallAvatar} />
                            </Grid>
                            <Grid item xs={10} md={11} lg={11}>
                              {option.nameWithLocationDescriptor}
                            </Grid>
                          </Grid>
                          <Typography className={classes.details} component="div" variant="caption">{`Page Id: ${option.pageId}`}</Typography>
                          <Typography className={classes.details} component="div" variant="caption">{`Address: ${getFacebookAddress(option?.locations[0]) || 'Not available'}`}</Typography>
                          {getPageErrors(option)}
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                          <Chip
                            size="small"
                            className={classes.chip}
                            label={!isLocationPage(option) ? t('brand_page_label') : t('location_page_label')}
                            data-cy="facebookPageSelectModal_pageType_chip"
                          />
                        </Grid>
                      </Grid>
                    )}
                    filterOptions={(options, { inputValue }) => _.filter(
                      options,
                      (option) => option?.nameWithLocationDescriptor?.toLowerCase()
                        .includes(inputValue.toLowerCase())
                        || option?.pageId?.toLowerCase().includes(inputValue.toLowerCase())
                        || getFacebookAddress(option?.locations[0]).toLocaleLowerCase()
                          .includes(inputValue.toLowerCase()),
                    )}
                    disabled={formDisabled}
                  />
                )}
                {filteredFacebookPages.length === 0 && selectedBussiness?.id && !loadingPages && (
                  <Typography variant="body1" className={classes.text}>
                    {t('facebook_page_select_modal_no_pages_body')}
                  </Typography>
                )}
                {showErrorMessage && (
                  <Box className={classes.errorContainer}>
                    <ErrorContent
                      pageId={selectedPage.pageId}
                      store={store}
                    />
                  </Box>
                )}
                {missingLatLong && (
                  <Typography variant="body1" className={classes.text}>
                    {t('facebook_page_location_latlong_error_text')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.bottom}>
            <AsyncButton
              className={classes.button}
              variant="outlined"
              onClick={handleCancel}
              disabled={formDisabled}
              data-cy="cta-multistore_confirm_facebook_cancel"
              size="large"
            >
              {t('cancel')}
            </AsyncButton>
            {!showErrorMessage && (
              <AsyncButton
                className={classes.button}
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                disabled={_.isEmpty(selectedPage)
                  || formDisabled}
                data-cy="cta-multistore_confirm_facebook"
                size="large"
              >
                {retry ? t('retry') : t('connect')}
              </AsyncButton>
            )}
            {showErrorMessage && (
              <AsyncButton
                className={classes.button}
                color="secondary"
                variant="contained"
                onClick={handleRetry}
                disabled={_.isEmpty(selectedPage)
                  || formDisabled}
                data-cy="cta-multistore_confirm_facebook_retry"
                size="large"
              >
                {t('retry')}
              </AsyncButton>
            )}
          </Box>
        </PageContentContainer>
      </FullScreenDialog>
    </div>
  );
};

export default FacebookPageSelectModal;
